<template>
  <div class="col s12 center preloader">
    <div class="preloader-wrapper big active">
      <div class="spinner-layer">
      <div class="circle-clipper left">
          <div class="circle"></div>
      </div><div class="gap-patch">
          <div class="circle"></div>
      </div><div class="circle-clipper right">
          <div class="circle"></div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  data () {
    return {
    }
  }
}
</script>

<style scoped>
</style>
