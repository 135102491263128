import axios from 'axios'
import {CONFIG} from '@/config/config.js'

export default {
  state: {
    loaded: false,
    login: null,
    uuid: null,
    nome: null,
    cpf: null,
    fotoUrl: null,
    pfId: null,
    oid: null,
    oeid: null,
    superUsuario: false,
    orgaoEntidade: {
      orgao: null,
      entidade: null,
      tipo: null
    }
  },
  mutations: {
    loadOrgaoData (state, orgaoData) {
      state.orgaoEntidade.orgao = orgaoData['orgao']
      state.orgaoEntidade.entidade = orgaoData['entidade']
      state.orgaoEntidade.tipo = orgaoData['tipo']
    },
    loadUserData (state, userData) {
      try {
        if (state.oeid !== userData['oeid']) {
          this.dispatch('loadOrgaoUsuarioInfo', {oid: userData['oid'], oeid: userData['oeid']})
        }

        state.login = userData['login']
        state.uuid = userData['uuid']
        state.nome = userData['nome']
        state.cpf = userData['login']
        state.fotoUrl = userData['foto']
        state.pfId = userData['pfid']
        state.oid = userData['oid']
        state.oeid = userData['oeid']
        state.superUsuario = (userData['superUsuario'] === 'S')
        state.loaded = true
      } catch (e) {
        console.error(`Falha ao carregar dados do usuário logado: ${e.toString()}`)
      }
    }
  },
  actions: {
    loadUserDataFromToken: async (context, token) => {
      try {
        if (token !== '') {
          var splitToken = token.split('.')
          if (splitToken.length > 1) {
            var tokenData = JSON.parse(window.atob(splitToken[1]))

            if ('user' in tokenData['data']) {
              return context.commit('loadUserData', tokenData['data']['user'])
            }
          }
        }
      } catch (e) {
        console.error(`Token inválido/inexistente: "+ ${e.toString()}`)
      }
    },
    loadOrgaoUsuarioInfo: async (context, ids) => {
      await axios.get(`${CONFIG.URL_ACCOUNT_API_V2}/v1/user-domain/`).then(response => {
        var domain = response.data.data.AccountAPI['domain']
        var orgaoData = {orgao: 'N/D', entidade: 'N/D', tipo: 'N/D'}
        for (var oid in domain) {
          if (oid === ids.oid) {
            orgaoData.orgao = domain[oid]['label']
            orgaoData.entidade = domain[oid][ids.oeid]['label']
            orgaoData.tipo = domain[oid][ids.oeid]['tipo']
          }
        }
        localStorage.user_domain = JSON.stringify(domain) // salva o user domain no localstorage para utilização no right-sidebar
        context.commit('loadOrgaoData', orgaoData)
      }).catch(error => {
        console.error(error)
        // TODO: handle AccountUserDomain error here
      })
    }
  }
}
