<template>
  <div>
    <header id="header1" class="">
      <nav id="" class="cor-app-pri-bg" role="navigation">
        <img
          class=""
          id="marca"
          src="https://static.onyxerp.com.br/img/logo-onyxerp-invertido.svg"
          alt="Marca"
        />
      </nav>
    </header>
    <main id="main1" class="">
      <h1 id="chamada_tit" class="">
        {{ primary_text }}
      </h1>
      <h4 id="chamada_desc" class="">
        {{ secondary_text }}
      </h4>
      <div id="appsBlk" class="row">
          <div class="col s12 no-padding">
            <ul id="apps">
              <template v-for="(app, i) in apps">
                <li v-bind:key="i" class="row s2">
                  <div class="circ appLnk">
                    <a v-bind:href="app['app_url']">
                      <img v-if="app['app_icone'].indexOf('http') === 0" class="material-icons" v-bind:src="app['app_icone']" height="40" />
                      <i v-else class="material-icons">{{ app['app_icone'] }}</i>
                      <span></span>
                    </a>
                  </div>
                  <p>{{ app['app_nome'] }}</p>
                </li>
              </template>
            </ul>
          </div>
      </div>
    </main>
    <div id="bg-app" class="">
      <div id="Itembg3" class="itemBg" v-bind:style="`background: transparent no-repeat fixed center 0% / cover url('${selected_bg_image.url}')`">
        <i class="authorBG tooltipped material-icons hide-on-med-and-down" data-position="top" data-delay="50" v-bind:data-tooltip="selected_bg_image.about">
          info
        </i>
        <div class="efeitoDashBG" style="opacity: 0.5"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      meta: this.$store.state.meta.data,
      apps: this.$store.state.meta.data.metas.value.ptbr.apps_list,
      images_bg_settings: this.$store.state.meta.data.metas.value.ptbr.img_bkgnd_loop,
      primary_text: this.$store.state.meta.data.metas.value.ptbr.tx_anon_msg_pri,
      secondary_text: this.$store.state.meta.data.metas.value.ptbr.tx_anon_msg_sec,
      selected_bg_image: {
        index: 0,
        about: null,
        url: null
      }
    }
  },
  methods: {
    initMaterialize(){
      setTimeout(() => M.Tooltip.init(document.querySelectorAll('.tooltipped')), 100)
    },
    background_images_rotate(){
      let index = (this.selected_bg_image.index < (this.images_bg_settings.loop_images.length -1) ? this.selected_bg_image.index + 1 : 0)
      let selected_bg = this.images_bg_settings.loop_images[index]
      this.selected_bg_image.index = index
      this.selected_bg_image.about = `${selected_bg.about}. ${selected_bg.author}.`
      this.selected_bg_image.url = selected_bg.img_url
      this.initMaterialize()
    }
  },
  beforeMount(){
    this.background_images_rotate()
  },
  mounted(){
    setInterval(() => {
      this.background_images_rotate()
    }, this.images_bg_settings.loop_interval);
  }
}
</script>
