var prot = 'https://'
var env = ''
var env_app = 'www'
var env_storage = 'production-onxerp'
var domain = '.onyxapis.com.br'

export default {
	API_KEY: 'ZmEwOWVmNTc4OTY0',
	APP_ID: 'fa09ef578964',
	APP_ID_ARQUIVOS: {
		RECAD: "8c37d37aa451",
		PROCESSOS: "cb14f6a4ea25"
	},
	TIME_ALIVE_TRIGGER_API: 180000,
	META_API_LOCALSTORAGE: 'meta-api-processos',
	URL_ARQUIVOS: `https://s3-sa-east-1.amazonaws.com/${env_storage}`,
	URL_RECAD_APP_V2: `https://${env_app}.onyxerp.com.br/Recad`,
	URL_SOCIAL_APP: `https://${env_app}.onyxerp.com.br/Social`,
	URL_PROTOKOL_API: prot + 'protokol-api' + env + domain,
	URL_GOV_API: prot + 'gov-api' + env + domain,
	URL_AUTH_API: prot + 'auth-api' + env + domain,
	URL_JWT_API: prot + 'jwt-api' + env + domain,
	URL_META_API: prot + 'meta-api-v2' + env + domain,
	URL_ACCOUNT_API: prot + 'account-api' + env + domain,
	URL_ACCOUNT_API_V2: prot + 'account-api-v2' + env + domain,
	URL_SOCIAL_API: prot + 'social-api' + env + domain,
	URL_SOCIAL_API_V2: prot + 'social-api-v2' + env + domain,
	URL_STORAGE_API: prot + 'storage-api' + env + domain,
	URL_DEVICE_API: prot + 'device-api' + env + domain,
	URL_DASHBOARD_API: prot + 'dashboard-api' + env + domain,
	URL_BIOMETRIA_API: prot + 'biometria-api' + env + domain,
	URL_RECAD_API: prot + 'recad-api' + env + domain,
	URL_RECAD_API_V2: prot + 'recad-api-v2' + env + domain,
	URL_UFCIDADE_API: prot + 'ufcidade-api' + env + domain,
	URL_FOPAG_API: prot + 'fopag-api' + env + domain,
	URL_RH_API_V2: prot + 'rh-api-v2' + env + domain,
    URL_NOTIFY_API: prot + 'notify-api' + env + domain,
	URL_NOTIFY_API_WS: 'wss://notify-api-ws' + env + domain,
	URL_PROCESSOS_API: prot + 'processos-api' + env + domain,
	URL_LOG_API: prot + 'log-api' + env + domain,
	URL_EXTERNAL_STORAGE: "https://storage3.onyxerp.com.br",
  Geolocalizacao: {
    'enableHighAccuracy': true,
    'timeout': 5000,
    'maximumAge': 0
  }
}

